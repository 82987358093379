
@mixin bp($point) {
    @if $point == extra-small {
      @media (max-width: 450px) { @content; }
    }
    @if $point == sm {
      @media (max-width: 576px) { @content; }
    }
    @if $point == min-sm {
      @media (min-width: 576px) { @content; }
    }
    @if $point == tablet {
        @media (max-width: 767.9px) { @content; }
    }
    @if $point == min-tablet {
        @media (min-width: 768px) { @content; }
    }
    @if $point == desktop {
        @media (max-width: 991px) { @content; }
    }
    @if $point == min-desktop {
        @media (min-width: 991.1px) { @content; }
    }
    @if $point == wide {
        @media (max-width: 1200px) { @content; }
    }
}

// for transition, translate
@mixin css3-prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}

// for animations
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
      @content;
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    }
}

// Absolute positioning
@mixin abs-position ($top, $right, $bottom, $left) {
    position: absolute; 
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

// Retina ready images
@mixin retina-image($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
        background-image: url($image);
        background-size: $width $height;
    }
}

@mixin font-generator($upto : 20) {
    @for $i from 10 through $upto {
        .font-#{$i} {
            font-size: #{$i}pt!important;
        }
    }
}

.mi-bg {
    font-size: 17pt;
    font-weight: 700;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.rb-btn {
    background-color: var(--color-md);
    border-radius: 5px;
    transition: background 300ms;
}

@mixin rb-btn_mix($padding: 5px 10px, $color: $color-xl, $hoverColor: $color-xxs) {
    color: $color;
    padding: $padding;
    &:hover {
        @extend .btn-effect;
        color: $hoverColor;
    }
}