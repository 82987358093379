.MuiTableHead-root {
    th {
        font-weight: 600;
        font-family: $font-fira;
        font-size: 12pt;
        padding: 16px !important;
    }
}

.cp {
    cursor: pointer;
}

.rmv-outline {
    outline: 0;
    &:hover, &:focus, &:active {
        outline: none;
    }
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.text-underline {
    text-decoration: underline !important;
}

.modal--wrapper {
    border: 0 !important;
    padding: 0px !important;
    &[data-size=lg] {
        min-width: 800px;
        width: 800px;
    }
    &[data-size=md] {
        min-width: 600px;
        width: 600px;
    }
    &[data-size=sm] {
        min-width: 500px;
    }
    @include bp(desktop) {
        &[data-size=lg] {
            min-width: 576px;
            max-width: 576px;
        }
        &[data-size=md] {
            min-width: 500px;
        }
        &[data-size=sm] {
            min-width: 450px;
        }
    }
    @include bp(sm) {
        &[data-size=lg], &[data-size=md], &[data-size=sm] {
            min-width: 450px;
        }
    }
    @include bp(extra-small) {
        &[data-size=lg], &[data-size=md], &[data-size=sm] {
            min-width: 330px;
        }
    }
}

.customInputClass {
    margin-right: 10px !important;
}

.bb-2 {
    border-bottom: 2px solid var(--color-xs);
}

.fb {
    &-40 {
        flex-basis: 38.5%;
    }
    &-50 {
        flex-basis: 48.5%;
        &-s {
            max-width: 48%;
            flex-basis: 50%;
            margin-right: 0px !important;
            margin-top: 5px !important;
            margin-bottom: 20px !important;
            // max-width: 45%;
            // flex-basis: 45%;
            // margin-right: 40px !important;
            // margin-top: 5px !important;
            // margin-bottom: 20px !important;
        }
    }
    &-60 {
        flex-basis: 60%;
    }
    @include bp(desktop) {
        &-40, &-50, &-60 {
            &-s {
                max-width: 48.5%;
                flex-basis: 48.5%;
                margin-right: 20px !important;
            }
        }
    }
    @include bp(tablet) {
        &-40, &-50, &-60 {
            flex-basis: 100%;
            &-s {
                max-width: 100%;
                flex-basis: 100%;
                margin-right: unset !important;
            }
        }
    }
}

.color {
    &-sm {
        color: var(--color-sm);
    }
}

// #google-map {
    .MuiSvgIcon-root {
        fill: var(--color-sm) !important;
    }
// }

#client-snackbar {
    svg {
        fill: white !important;
    }
}


.fsize {
    &-8 {
        font-size: 8px !important;
    }
    &-16 {
        font-size: 16px !important;
    }
    &-24 {
        font-size: 24px !important;
    }
    &-32 {
        font-size: 32px !important;
    }
    &-64 {
        font-size: 64px !important;
    }
    &-28 {
        font-size: 28px !important;
    }
    &-48 {
        font-size: 48px !important;
    }
    &-60 {
        font-size: 60px !important;
    }
}

.transform {
    &-initial {
        text-transform: initial !important;
    }
}

.color {
    &-21 {
        color: var(--color-21);
    }
}

.MuiAvatar-root {
    width: 60px;
    height: 60px;
    text-transform: uppercase;
}