.top--cover {
    display: flex;
    &_wrap {
        margin: auto;
        h1, h4 {
            color: var(--color-xxs);
            text-align: center !important;
        }
        h4 {
            margin-top: 30px;
        }
    }
}

.hiw--section {
    padding: 50px 0 20px;
    display: flex;
    &_wrap {
        width: 100%;
        text-align: center;
        h5 {
            margin-top: 20px;
        }
        .card {
            font-family: $font-roboto;
            text-align: left;
            img {
                height: 48px;
                width: 48px;
            }
            p {
                color: var(--color-md);
                font-size: 11.5pt;
            }
        }
    }
}

.split--section {
    padding: 70px 0 40px;
    display: flex;
    &_wrap {
        width: 100%;
        h5 {
            margin-top: 20px;
            line-height: 1.67;
            color: var(--color-md);
        }
    }
}

.auth--container {
    min-height: 100vh;
    .left, .right {
        flex-basis: 50%;
    }
    .left {
        background-image: url(/assets/images/login_left.svg);
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 100vh;
    }
    &_right {
        position: relative;
        margin: auto 100px;
        h2 {
            font-weight: 400;
        }
        h5 {
            color: var(--color-61);
            font-weight: 400;
            font-size: 1.25em;
        }
        .bottom-block {
            width: 100%;
            padding: 0px;
            position: absolute;
            top: 100%;
            border-top: 1px solid var(--color-xs);
            transform: translateY(-100%);
        }
    }
    .left {
        &.animate {
            flex-basis: 30%;
            width: 30%;
            transition: all 300ms;

        }
        &:not(.animate) {
            transition: all 300ms;
        }
    }
    .middle {
        width: 20%;
        flex-basis: 20%;
        &::before {
            height: 100vh;
            width: 1px;
            background-color: var(--color-xs);
            content: "";
            position: absolute;
            left: 38.9%;
            z-index: -1;
            transform: translateX(-38.9%);
        }
        > div {
            z-index: 1;
        }
    }
    .MuiStepConnector-vertical {
        margin-left: 38px !important;
        span { 
            min-height: 100px; 
        }
    }
    @include bp(desktop) {
        .left {
            flex-basis: 30%;
            &.animate {
                width: 0;
                flex-basis: 0;
            }
        }
        .left.animate + .middle {
            width: 30%;
            flex-basis: 30%;
            &::before {
                content: unset;
            }
        }
        .left.animate + .middle + .right {
            width: 70%;
            flex-basis: 70%;
        }
        .left:not(.animate) + .right {
            width: 70%;
            flex-basis: 70%;
        }

        .MuiStepConnector-vertical {
            margin-left: 25px !important;
        }
    }
    @include bp(tablet) {
        flex-direction: column;
        .left {
            display: none;
        }
        .left.animate + .middle + .right {
            min-height: 100vh;
            width: 100%;
            flex-basis: 100%;
            .auth--container_right {
                margin: 0 50px;
                height: 100vh !important;
                > div {
                    margin-top: 50px!important;
                }
            }
        }
        .left.animate + .middle {
            width: 100%;
            flex-basis: 100%;
            margin-top: 50px;
        }
        .MuiStepper-root {
            flex-direction: row !important;
        }
        .MuiStepConnector-root {
            visibility: hidden;
        }
        .left:not(.animate) + .right {
            margin: auto 0 !important;
            flex-basis: 100%;
            width: 100%;
            .auth--container_right {
                margin: 0 50px;
                height: 100vh !important;
                min-height: 600px;
            }
        }
    }
    @include bp(sm) {
        .left.animate + .middle + .right {
            .auth--container_right {
                margin: auto 20px;
            }
        }
        .left:not(.animate) + .right {
            .auth--container_right {
                margin: 0 20px;
            }
        }
    }
}

.organisation--container {
    padding: 60px 0;
    @include bp(tablet) {
        margin: 0 20px;
        width: 100%;
        max-width: calc(100% - 40px);
    }
}

.form-wrap {
    padding: 35px;
    &_row:not(:first-child) {
        // margin-top: 20px;
    }
    &_row:last-child {
        margin-top: 20px;
    }
    &_flex {
        flex-grow: 1;
    }
    &_responsive {
        justify-content: space-between;
        flex-direction: row;
        @include bp(tablet) {
            flex-direction: column;
        }
    }
}

.divider {
    position: relative;
    &-row {
        margin: 20px 0;
        &::after {
            content: "";
            width: 100%;
            position: absolute;
            top: 100%;
            transform: translateY(100%);
            height: 3px;
            background-color: rgba(grey, 0.2);
        }
    }
}

.storeBtn {
    background-color: var(--color-xl);
    padding: 10px 25px;
    border-radius: 10px;
    margin-top: 20px;
    h3, h4, h5, h6 {
        line-height: 1.2;
        margin: 0;
        letter-spacing: 0.025rem;
        padding: 0;
        color: var(--color-xxs);
    }
    img {
        width: 40px;
        height: 40px;
    }
    text-decoration: none !important;
    color: var(--color-xxs);
    transition: opacity 200ms;
    box-shadow: var(--card-shadow);
    position: relative;
    &:hover {
        opacity: 0.85;
    }
    &:last-child {
        margin-left: 20px;
    }
    @include bp(sm) {
        &:last-child {
            margin-left: 0;
        }
    }
}

.txTable {
    th, tr, td {
        border: 0;
        font-size: 20px;
        font-weight: 500;
        padding-bottom: 10px;
    }
    th {
        // color: var(--color-sm);
        color: #9E9E9E;
    }
    td {
        color: #424242;
    }
}

.not-verified {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .img-wrap {
        margin: auto;
        text-align: center;
    }
    p {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }
}

button[data-label="add-logo"] {
    border: 1px dotted grey;
    height: 60px;
    width: 50px;
    vertical-align: middle;
    border-radius: 50%;
    font-size: 8pt !important;
}