@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css");
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url("https://use.typekit.net/vja0nps.css");
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700&display=swap');


$font-poppins: 'Poppins',
sans-serif;
$font-roboto : 'Roboto',
sans-serif;
$font-oswald : 'Oswald',
sans-serif;
$font-din: "urw-din",
sans-serif;
$font-fira: 'Fira Sans', sans-serif;

:root {
    --color-xxs: #ffffff;
    --color-xs : #D3D3D3;
    --color-sm : #989898;
    // --color-lg : #6d6d6d;
    --color-lg : #424242;
    --color-md : #333;
    --color-xl : #000;
    --color-xl : #1E1E1E;
    --color-1: #EA5A16;
    // --bg-cover-gradient: linear-gradient(90deg, rgba(218,45,58,1) 0%, rgba(234,90,23,0.8) 81%, rgba(226,116,35,1) 100%);
    --bg-cover-gradient: linear-gradient(90deg, rgba(227, 31, 59, 0.95) 0%, rgba(231, 118, 23, 0.85) 100%);
    // --primary-gradient: linear-gradient(90deg, rgba(218,45,58,1) 0%, rgba(234,90,23,0.8) 100%, rgba(226,116,35,1) 100%);
    --primary-gradient: linear-gradient(90deg, rgba(227, 31, 59, 1) 0%, rgba(231, 118, 23, 1) 100%);
    --card-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);

    --color-61: #616161;
    --color-21: #212121;
}

html,
body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    font-family: $font-fira;
}

body {
    overflow-x: hidden !important;
    line-height: 1.5;
    font-size: 14px;
}

* {
    -webkit-tap-highlight-color: transparent;
}

@import "./mixins";
@import "./helpers";

h1 {
    font-size: 3.65em;
    font-family: $font-fira;
    @include bp(sm) {
        font-size: 3em;
    }
}

h4 {
    font-size: 1.8em;
}

.h-inherit {
    height: inherit;
    min-height: inherit;
}

.extra-padding {
    padding-left: 80px !important;
    padding-right: 80px !important;
}

.max-100 {
    max-height: 80vh;
    min-height: 400px;
    @include bp(tablet) {
        max-height: 400px;
    }
}

.br-2 {
    border-radius: 2px;
}

footer {
    background: var(--primary-gradient);
    img {
        width: 40px;
        height: 40px;
        object-fit: cover;
    }
    .social-footer {
        a img {
            width: 30px;
            height: 30px;
        }
        a {
            margin-right: 20px;
        }
    }
}

#formResponse {
    color: var(--color-1);
}

.color-1-imp {
    color: var(--color-1) !important;
    * {
        color: var(--color-1) !important;
    }
}

.bg-1-imp {
    background-color: var(--color-1) !important;
    * {
        background-color: var(--color-1) !important;
    }
}

.toLower {
    text-transform: none !important;
}

input:disabled {
    cursor: not-allowed;
}

.btn-wrap {
    position: relative;
    .stripBtn {
        padding: 10px 40px !important;
    }
}

.w-150 {
    min-width: 150px;
}

.img-wrap {
    .thumbnail {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 10px;
        &-lg {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin: 0;
        }
    }
}

.defNavLink {
    color: var(--color-1);
    &:hover {
        color: var(--color-1);
        opacity: 0.9;
    }
}

.max-w40 {
    width: 40%;
}
.max-w30 {
    width: 30%;
}
.max-w25 {
    width: 25%;
}
.max-w15 {
    width: 15%;
}

a, p, span, h1, h2, h3, h4, h5, h6 {
    font-family: $font-fira;
}

@import "./common.scss";

.customInputClass.mr-0 {
    margin-right: 0 !important;
}

.editBtn {
    background-color: rgba(204, 204, 204, 0.4);
    padding: 4px 8px;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0,0,0,0.05) !important;
    outline: none !important;
    border: 0 !important;
    i.material-icons {
        vertical-align: middle;
        font-size: 14pt;
        color: var(--color-61);
    }
    &:hover, &:active, &:focus-within, &:focus-visible {
        outline: none !important;
        border: 0 !important;
        box-shadow: 0 0 4px rgba(0,0,0,0.1) !important;
    }
    &.bg-trans {
        background-color: transparent;
    }
}

.contained-overlay {
    position: absolute;
    left: -3px;
    top: 7px;
    background-color: #c2c2c224;
    width: 100%;
    height: 100%;
    background-size: 100%;
    z-index: 9999 !important;
}

button:disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.animBtnGrow {
    transform: scale(1);
    animation: growBtn 300ms ease-in-out;
}

@include keyframes(growBtn) {
    0% {
        transform: scale(0) rotate(0deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

.MuiSelect-select.Mui-disabled, input:disabled, textarea:disabled {
    color: rgba(0, 0, 0, 0.733);
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.5) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -webkit-appearance: none !important;
    appearance: none !important;
    -moz-appearance: textfield;
}

.topHeader-logout {
    position: absolute;
    top: 0;
    right: 0;
}

.font-10pt * {
    font-size: 10pt !important;
}

#employeeRoadblock .MuiIconButton-label,
#google-map-store .MuiIconButton-label {
    display: none !important;
}

.MuiAutocomplete-popper {
    // display: none !important;
}
.easyCropImage {
    .crop-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 80px;
        .reactEasyCrop_Image {
            max-width: 600px;
            height: auto;
        }
        .reactEasyCrop_CropArea {
            max-width: 600px;
        }
    }

    .controls {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 50%;
        transform: translateX(-50%);
        height: 80px;
        display: flex;
        align-items: center;
        background-color: white;
        width: 100%;
        &-inner {
            margin: auto;
            width: 400px;
            display: flex;
            align-items: center;
            &_btn {
                background-color: var(--color-1);
                color: white;
                padding: 5px 20px;
                margin-left: 20px;
            }
        }
        .MuiSlider-root {
            color: var(--color-1);
        }
        .MuiSlider-thumb {
            color: var(--color-1);
        }
    }

    .slider {
        padding: 22px 0px;
    }
}

.avatar-img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
}

.muiErrorColor {
    color: #f44336 !important;
}

.removeDropIcon {
    -webkit-appearance: none !important;
    appearance: none !important;
    .MuiAutocomplete-popupIndicator {
        display: none !important;
    }
}

.passwordListing {
    li {
        &::before {
            content: "\e888";
            font-size: 14pt;
            font-weight: 700;
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            padding: 5px;
            vertical-align: middle;
            // border: 1px solid black;
            color: var(--color-sm);
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
        &[data-check=true]::before {
            content: "\e86c";
            color: green;
        }
    }
}